import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { navigationEmptyState } from './getInvestmentNavigation.model';
import { getInvestmentDetailsUrl, getInvestmentNavigationParams, getRolloverInvestmentUrl, getTrackInvestmentUrl } from './util';

/**
 * Returns investment urls.
 */
export const getInvestmentNavigation = _ref => {
  let {
    managementStrategy,
    origin,
    record,
    recordGates
  } = _ref;
  if (!origin || !record || !record.portfolioAvailable || !recordGates) {
    return _objectSpread({}, navigationEmptyState);
  }
  return {
    params: getInvestmentNavigationParams({
      origin,
      record,
      recordGates
    }),
    urls: {
      details: getInvestmentDetailsUrl({
        origin,
        record,
        managementStrategy
      }),
      tracking: getTrackInvestmentUrl({
        record,
        recordGates
      }),
      rollover: getRolloverInvestmentUrl({
        record
      })
    }
  };
};