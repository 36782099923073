import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Icon from "@mui/material/Icon";
var __jsx = React.createElement;
import React, { useEffect, useRef, useState } from 'react';
import isString from "lodash/isString";
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoImage from '../../assets/logos/logo-ys-2022.png';
import { testIds } from './PageSplit.model';
import { BackNavigation, ContentHeader, ContentHolder, ContentLeft, ContentRight, FooterContentLeft, FooterContentRight, LeftPageContent, Logo, LogoSecondary, LogosHolder, RightPageContent, SPageSplit } from './PageSplit.style';
const BackgroundWrapper = _ref => {
  let {
    backgroundImage,
    children
  } = _ref;
  return __jsx(React.Fragment, null, backgroundImage && !isString(backgroundImage) ? backgroundImage(children) : children);
};
export const PageSplit = _ref2 => {
  let {
    showLogo,
    secondLogo,
    backNavigation,
    backNavigationText,
    backNavigationCallback,
    title,
    titleTagType,
    label,
    sideContent,
    footerContent,
    backgroundColor,
    hideBackgroundImageMobile,
    backgroundImage,
    middleSplit,
    children,
    className
  } = _ref2;
  const {
    0: headerHeight,
    1: setHeight
  } = useState(0);
  const elementRef = useRef(null);
  useEffect(() => {
    var _elementRef$current;
    if ((showLogo || backNavigation) && elementRef !== null && elementRef !== void 0 && (_elementRef$current = elementRef.current) !== null && _elementRef$current !== void 0 && _elementRef$current.offsetHeight && headerHeight !== elementRef.current.offsetHeight) {
      var _elementRef$current2;
      setHeight(elementRef === null || elementRef === void 0 ? void 0 : (_elementRef$current2 = elementRef.current) === null || _elementRef$current2 === void 0 ? void 0 : _elementRef$current2.offsetHeight);
    }
  }, [backNavigation, elementRef, headerHeight, showLogo]);
  return __jsx(SPageSplit, _extends({
    backgroundColor: backgroundColor,
    className: className
  }, testIds.base.attr), __jsx(LeftPageContent, {
    middleSplit: middleSplit,
    className: "page-split-left-content"
  }, __jsx(ContentHolder, null, __jsx(ContentHeader, {
    ref: elementRef
  }, showLogo ? __jsx(LogosHolder, _extends({
    className: "page-split-logos-holder",
    center: !secondLogo
  }, testIds.logo.attr), __jsx(Logo, null, __jsx("a", {
    href: "/"
  }, __jsx("img", {
    width: "177",
    src: LogoImage
  }))), secondLogo ? __jsx(React.Fragment, null, __jsx(_Icon, {
    sx: {
      mx: 2
    }
  }, __jsx(AddIcon, null)), __jsx(LogoSecondary, null, secondLogo)) : null) : null, backNavigation ? __jsx(BackNavigation, _extends({
    direction: "row",
    onClick: e => {
      e.preventDefault();
      backNavigationCallback && backNavigationCallback();
    }
  }, testIds.backNavigation.attr), __jsx(ChevronLeftIcon, {
    sx: {
      mr: 2
    }
  }), __jsx(_Typography, {
    component: "span"
  }, backNavigationText)) : null), __jsx(ContentLeft, null, label ? __jsx(_Typography, _extends({
    component: "p",
    variant: "body1",
    sx: {
      marginBottom: 5
    }
  }, testIds.label.attr), label) : null, title ? __jsx(_Typography, _extends({
    component: titleTagType ? `h${titleTagType}` : 'h3',
    variant: "h3",
    sx: {
      marginBottom: 5
    }
  }, testIds.title.attr), title) : null, sideContent)), footerContent && __jsx(FooterContentLeft, null, footerContent)), __jsx(BackgroundWrapper, {
    backgroundImage: backgroundImage
  }, __jsx(RightPageContent, {
    backgroundImage: backgroundImage,
    hideBackgroundImageMobile: hideBackgroundImageMobile,
    middleSplit: middleSplit,
    headerHeight: headerHeight
  }, __jsx(ContentRight, null, children))), footerContent && __jsx(FooterContentRight, null, footerContent));
};