import { gql } from 'graphql-request';
import { graphqlApi, Tags } from '../common';
export const portfolioGraphQLApi = graphqlApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioInvestmentsByStatus, Tags.PortfolioInvestmentRolloverAmount]
}).injectEndpoints({
  endpoints: builder => ({
    getPortfolioInvestments: builder.query({
      providesTags: [Tags.PortfolioInvestmentsByStatus],
      query: _ref => {
        let {
          actionableItemsOnly,
          asOfDate,
          investorId,
          managementStrategy,
          status,
          userId
        } = _ref;
        return {
          document: gql`
            query getPortfolioInvestments(
              $actionableItemsOnly: Boolean
              $asOfDate: String
              $investorId: String
              $managementStrategy: String
              $status: [String]
              $userId: String!
            ) {
              investmentByStatus(
                actionableItemsOnly: $actionableItemsOnly
                asOfDate: $asOfDate
                investorAccountId: $investorId
                managementStrategy: $managementStrategy
                portfolioId: $userId
                status: $status
              ) {
                ACTIVE {
                  gates
                  investments {
                    allowRollover
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    portfolioAvailable
                    requiresUserAction
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                PENDING {
                  gates
                  investments {
                    allowRollover
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    portfolioAvailable
                    requiresUserAction
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                MATURED {
                  investments {
                    allowRollover
                    fundType
                    investmentAmount
                    investorAccountId
                    irr
                    netTotalRepaid
                    noteTitle
                    noteUrlHash
                    portfolioAvailable
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    investmentAmount
                    irr
                    netTotalRepaid
                  }
                }
                COMMITMENT_PENDING {
                  commitments {
                    amount
                    fundExpensesFeeType
                    fundProvider
                    fundType
                    investorAccountId
                    launchedDate
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    portfolioAvailable
                    termCurrent
                    terminationDate
                    termPercentage
                    termPhrase
                    termRemaining
                    waitlisted
                  }
                  totals {
                    amount
                  }
                }
                COMMITMENT_ACTIVE {
                  commitments {
                    amount
                    fundExpensesFeeType
                    fundProvider
                    fundType
                    investorAccountId
                    launchedDate
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    portfolioAvailable
                    termCurrent
                    terminationDate
                    termPercentage
                    termPhrase
                    termRemaining
                    waitlisted
                  }
                  totals {
                    amount
                  }
                }
              }
              lastCommentary(userId: $userId) {
                offerings
              }
            }
          `,
          variables: {
            actionableItemsOnly,
            asOfDate,
            investorId,
            managementStrategy,
            status,
            userId
          }
        };
      }
    }),
    getRolloverAmount: builder.query({
      providesTags: [Tags.PortfolioInvestmentRolloverAmount],
      query: _ref2 => {
        let {
          userId,
          investorId,
          noteUrlHash
        } = _ref2;
        return {
          document: gql`
            query getRolloverAmount($investorId: String!, $userId: String!, $noteUrlHash: String!) {
              maxRolloverAmount(
                portfolioId: $userId
                investorAccountId: $investorId
                noteUrlHash: $noteUrlHash
              ) {
                userId
                maturityDate
                rolloverAmountPerInvestment
                totals {
                  investmentIds
                  maxRolloverAmount
                }
              }
            }
          `,
          variables: {
            userId,
            investorId,
            noteUrlHash
          }
        };
      }
    }),
    getDocuments: builder.query({
      query: _ref3 => {
        let {
          fundId,
          investorAccountId
        } = _ref3;
        return {
          document: gql`
            query getPcap($investorAccountId: String!, $fundId: String!) {
              pcap(investorAccountId: $investorAccountId, fundId: $fundId) {
                available
              }
            }
          `,
          variables: {
            investorAccountId,
            fundId
          }
        };
      }
    })
  })
});